import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import AboutPage from './components/pages/AboutPage';
import Projects from './components/pages/Projects';
import Contact from './components/Contact';

function App() {
  // const [isMobile, setIsMobile] = useState(false);

  // useEffect(() => {
  //   const handleResize = () => {
  //     setIsMobile(window.innerWidth <= 1024);
  //   }
  //   window.addEventListener('resize', handleResize);
  //   handleResize()

  //   return () => window.removeEventListener('resize', handleResize);
  // },[])
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path='/' element={<Home/>} />
          <Route path='/about' element={<AboutPage/>} />
          <Route path='/projects' element={<Projects/>} />
          <Route path='/contact' element={<Contact/>} />
        </Routes>
        {/* {isMobil ? < } */}

      <Footer/>
      </Router>
    </>
  );
}

export default App;