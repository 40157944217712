import React from "react";
import "./Footer.css";

function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-links">
        <div className="footer-link-items">
          {/* <h2>Contact Info</h2> */}
        </div>
      </div>
      <section className="social-media">
        <div className="social-media-wrap">
          <a className="email-address" href="mailto:yamauchimaaya@gmail.com">
            yamauchimaaya@gmail.com
          </a>

          <div className="social-icons">
            <a
              className="social-icon-link linkedin"
              href="https://www.linkedin.com/in/maaya-yamauchi-856946a5/"
              rel="noreferrer"
              target="_blank"
              aria-label="LinkedIn"
            >
              <i className="fab fa-linkedin" />
            </a>
            <a
              className="social-icon-link github"
              href="https://github.com/mayamauchi"
              rel="noreferrer"
              target="_blank"
              aria-label="Github"
            >
              <i className="fab fa-github" />
            </a>
            {/* <a
              className="social-icon-link twitter"
              href="https://twitter.com/maaya_yamauchi"
              rel="noreferrer"
              target="_blank"
              aria-label="Twitter"
            >
              <i className="fab fa-twitter" />
            </a> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
