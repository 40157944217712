import React from 'react';

function CardItem(props) {
  return (
    <>
      <li className='cards__item'>
          <figure className='cards__item__pic-wrap'>
            <img
              className='cards__item__img'
              alt='Travel pic'
              src={props.src}
            />
          </figure>
       </li>
    </>
  );
}

export default CardItem;