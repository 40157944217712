import React from "react";

function ProjectItem(props) {

    function handleClick() {
        window.location.href = props.url
    }
  return (
    <>
      <li className="projects__item">
        <div className="projects__item__link" to={props.path}>
        <h2 className="projects__item__header">{props.header}</h2>

          <figure className="projects__item__pic-wrap" data-category={props.label}>
            <img
              className="projects__item__img"
              alt="pic"
              src={props.src}
              onClick = {handleClick}
            />
          </figure>
          <div className="projects__item__info">
            {props.text}
           
          </div>
          <div className="projects__item__git">
          <span>{props.git}</span>
          <span>{props.deploy}</span>
          </div>
        </div>
      </li>
    </>
  );
}

export default ProjectItem;
