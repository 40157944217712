import React from "react";
import "./Cards.css";
import CardItem from "./CardItem";

function Cards() {
  return (
    <div className="cards">
      <h2 className = "cards__header">Tech Skills</h2>
      <div className="cards__container">
        <div className="cards__wrapper">
          <ul className="cards__items">
            <CardItem src="images/html.png" />
            <CardItem src="images/CSS.png" />
            <CardItem src="images/javascript.png" />
            <CardItem src="images/react.png" />
            <CardItem src="images/node.png" />
            <CardItem src="images/psql.png" />
          </ul>
          <ul className="cards__items">
            
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
