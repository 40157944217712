import React from "react";

function AboutItem(props) {
  return (
    <>
      <div className="about__item">
        <div className="about-text">
          <h2 className="about__item__header">{props.header}</h2>
          <div className="about__item__info">{props.text}</div>
        </div>

        <figure className="about__item__pic-wrap" data-category={props.label}>
          <img className="about__item__img" alt="pic" src={props.src} />
        </figure>
      </div>
    </>
  );
}

export default AboutItem;
