import React from "react";
import "../App.css";
import "./Hero.css";
import { Button } from "./Button";
import Contact from "./Contact";

function Hero() {
  return (
    <div className="hero-container">
      <video src="/videos/video-3.mp4" autoPlay loop muted />
      <h1>MAAYA YAMAUCHI</h1>
      <p>Software Engineer</p>
      <div className="hero-btns">
        <Button
          className='btns'
          buttonStyle='btn--outline'
          buttonSize='btn--large'
          onClick={Contact}
        >
          Contact Me
        </Button>
        
      </div>
    </div>
  );
}

export default Hero;
