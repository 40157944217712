import React from "react";
import "../../App.css";
import Cards from "../Cards";
import Hero from "../Hero";
import About from "../About";
import Projects from "./Projects";
import Contact from "../Contact";
// import Social from "../Social";

function Home() {
  return (
    <>
      {/* <section id="social">
      <Social/>
      </section> */}
      <section id="home">
        <Hero />
      </section>
      <section id="about">
        <About />
        <Cards />
      </section>
      <section id="projects">
        <Projects />
      </section>
      <section id="contact">
        <Contact />
      </section>
    </>
  );
}

export default Home;
