import React from "react";
import "./About.css";
import AboutItem from "./AboutItem";

function About() {
  return (
    <div className="about__section">
      <h2 className="about">ABOUT</h2>
      <div className="about__container">
        <div className="about__wrapper">
          <ul className="about__items">
            <AboutItem
              header="Hello There!"
              src="images/Maaya.jpg"
              text={
                <>
                  <p className="about__item__text">
                    My name is Maaya and I'm a Software Engineer. I have a
                    Finance and startup background and I transitioned into tech
                    in December 2022.
                  </p>
                  <br />
                  <p className="about__item__text">
                  Coming from a non-technical background, I choose to pursue Software Engineering because it is rewarding, challenging, and always evolving. Given my professional background and being a TCK (third-culture kid), adapting and being exposed to new concepts and difficulties have been a way of life. I'm eager to enter a field that is ever-changing, where my self-starter skills and curiosity bring value.
                  </p>

                  <br />
                  <p className="about__item__text">
                    I love my work to have a positive impact. I'm
                    currently a Software Engineer at Earth Hero and I'm also an open-source contributor to a Chicago-based project tackling climate change.
                  </p>
                </>
              }
            />
          </ul>
          {/* <ul className="about__items">
            <AboutItem
              header="WEB DEVELOPMENT"
              src="images/coding.jpg"
              text={
                <>
                <p className="about__item__text">
                Entering the tech world without any prior IT or computer science knowledge was difficult but rewarding. Not only does it put your creativity to the test, but it also motivates you to keep growing and pushing yourself. I enjoy speaking up in support of causes I care about. I want to use my expertise to develop innovation that will benefit people and have a positive social influence.
                </p>
                </>
              }
            />
          </ul> */}
          {/* <ul className="about__items">
            <AboutItem
              header="TRAVELING"
              text={`I am a first-generation immigrant who was born and raised in Chicago, but because of the work my father did,  I had the opportunity to see many cultures. Before returning to pursue my degree in the US, I lived in Japan and Malaysia. By being exposed to diverse cultures, this experience has broadened my perspective on how I want to live. As a third culture kid, my goal is to travel to different countries, learn more about other cultures, and expose myself to everything this life has to offer.`}
              src="images/traveling.jpg"
            />
          </ul>
          <ul className="about__items">
            <AboutItem
              header="DOG TRAINING"
              src="images/dog training.jpg"
              text="I didn't get my first dog until I was an adult. I assumed training would be quite easy, but that was not the case. I had to do a lot of research and training because of his anxiety. My six years in the pet-care sector exposed me to a variety of breeds and canine habits, which allowed me to learn more about pet psychology, history, and behavior.
              Dog training is intriguing because, in addition to learning about canines, you also learn about your own behavior. It benefits everyone!"
            />
          </ul> */}
        </div>
      </div>
    </div>
  );
}

export default About;
