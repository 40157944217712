import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";


const Result = () => {
  return <p>Thank you for reaching out! I will get back to you shortly.</p>;
};

function Contact() {
  const form = useRef();
  const [result, showResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1i5f6en",
        "template_wny6194",
        form.current,
        "8wDy2mWcxhzvlQBhQ"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    showResult(true);
  };

  //hide result
  setTimeout(() => {
    showResult(false);
  }, 5000);

  return (
    <>
    <h2 className="contact">CONTACT</h2>

    <div className="contact-form">
      <form ref={form} onSubmit={sendEmail} className="contact-input">
        <label className="contact-name">
          <input
            className="contact-field"
            placeholder="Full Name"
            type="text"
            name="from_name"
            required
          ></input>
        </label>
        <label className="contact-email">
          <input
            className="contact-field"
            placeholder="Email"
            type="email"
            name="reply_to"
            required
          ></input>
        </label>
        <textarea
          className="contactMessage"
          type="text"
          label="Message"
          placeholder="Message"
          name="message"
          required
        />
        <button className="contact-form-button">Submit</button>
        <div className="row">{result ? <Result /> : null}</div>
      </form>
    </div>
    </>
  );
}

export default Contact;
