import React from "react";
import "../Projects.css";
import ProjectItem from "../ProjectItem";

function Projects() {
  return (
    <div className="projects__section">
      <h2 className="projects">PROJECTS</h2>
      <div className="projects__container">
        <div className="projects__wrapper">
          <ul className="projects__items">
            <ProjectItem
              header={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://splendid-axolotl-f39844.netlify.app/"
                >
                  DimTech
                </a>
              }
              url="https://splendid-axolotl-f39844.netlify.app/"
              src="images/Dimtech.png"
              text={
                <>
                  <p className="projects__item__text">
                    A full-stack e-commerce web application that was built for
                    my Capstone Project at Fullstack Academy. This application
                    allows users to browse and purchase galactic-themed
                    products. A RESTful API is used to connect the frontend to
                    the backend, which is constructed using React and Redux.
                    Users can make purchases at the checkout using the Stripe
                    API. Express and Node.js are used to create the backend. We
                    utilized PostgreSQL as the database for this application.
                  </p>
                </>
              }
              git={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/mayamauchi/FSA-Capstone-EcommerceSite"
                >
                  <i className="fa-brands fa-github"></i>
                </a>
              }
              deploy={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://splendid-axolotl-f39844.netlify.app/"
                >
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
              }
            />
          </ul>
          <ul className="projects__items">
            <ProjectItem
              header={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://planetquack.onrender.com/"
                >
                  Solar System
                </a>
              }
              url="https://planetquack.onrender.com/"
              src="images/solarplanet.png"
              text={
                <>
                  <p className="projects__item__text">
                    A full-stack single page application using PostgreSQL,
                    Express, Typescript, and Node.js. Along with 3 other
                    members, I created this project for Dear Junior Dev's
                    hackathon.
                  </p>
                  <br />
                  <p className="projects__item__text">
                    All planets were curated using purely CSS. I was in charge
                    of the planets' databases and backend APIs, including those
                    for dwarf planets.
                  </p>
                </>
              }
              git={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/TeamPlanetQuack"
                >
                  <i className="fa-brands fa-github"></i>
                </a>
              }
              deploy={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://planetquack.onrender.com/"
                >
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
              }
            />
          </ul>
          <ul className="projects__items">
            <ProjectItem
              header={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://polite-horse-f204e7.netlify.app/"
                >
                  Quotes from Tech Mates
                </a>
              }
              url="https://polite-horse-f204e7.netlify.app/"
              src="images/Quotes.jpg "
              text={
                <>
                  <p className="projects__item__text">
                    I compiled quotes from my network on LinkedIn, where I asked
                    people about the quotes or sayings they have turned to when
                    facing burnout or imposter syndrome. This application
                    incorporates those collected quotes with the aim of
                    inspiring and motivating individuals.
                  </p>
                  <br />
                  <p className="projects__item__text">
                    This fullstack application was developed using React, Vite,
                    Express, Node.js, and PostgreSQL. The frontend part is
                    deployed on Netlify, while the backend is deployed on
                    Heroku.
                  </p>
                </>
              }
              git={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/mayamauchi/quote-generator"
                >
                  <i className="fa-brands fa-github"></i>
                </a>
              }
              deploy={
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://polite-horse-f204e7.netlify.app/"
                >
                  <i className="fa-solid fa-arrow-up-right-from-square"></i>
                </a>
              }
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Projects;
