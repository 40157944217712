import React from "react";
import "../../App.css";
import About from "../About";

function AboutPage() {
  return (
    <>
      <About />
    </>
  );
}

export default AboutPage;
